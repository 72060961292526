<template>
    <div>
        <h1 class="mb-1 mt-50 text-danger">
            logged user page
        </h1>
    </div>
</template>
<script>

export default {
    data() {
        return {
        }
    },
}
</script>